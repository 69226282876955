export const logoNegative = ['608 134', `
    <title>Just a random Logo</title>

    <svg id="Layer_2" enable-background="new 0 0 64 64" viewBox="0 0 64 64" height="50" width="50" xmlns="http://www.w3.org/2000/svg">
    <path d="m8 34h48v8h-48z" fill="#edeff1"/>
    <path d="m20 46h24v8h-24z" fill="#98ca69"/>
    <path d="m37 19h-13v-2.566l5.485-3.291 1.03 1.714-3.571 2.143h10.056z" fill="#6e83b7"/>
    <path d="m34.515 26.857-1.03-1.714 3.571-2.143h-10.056v-2h13v2.566z" fill="#6e83b7"/>
    <circle cx="52" cy="20" fill="#ffe352" r="10"/>
    <path d="m53 23h-2c-.551 0-1-.448-1-1v-1h3v-2h-3v-1c0-.552.449-1 1-1h2c.551 0 1 .448 1 1h2c0-1.654-1.346-3-3-3h-2c-1.654 0-3 1.346-3 3v1h-1v2h1v1c0 1.654 1.346 3 3 3h2c1.654 0 3-1.346 3-3h-2c0 .552-.449 1-1 1z" fill="#6e83b7"/>
    <circle cx="12" cy="20" fill="#ffe352" r="10"/>
    <path d="m13 19h-2c-.551 0-1-.448-1-1s.449-1 1-1h2c.551 0 1 .448 1 1h2c0-1.654-1.346-3-3-3v-1h-2v1c-1.654 0-3 1.346-3 3s1.346 3 3 3h2c.551 0 1 .448 1 1s-.449 1-1 1h-2c-.551 0-1-.448-1-1h-2c0 1.654 1.346 3 3 3v1h2v-1c1.654 0 3-1.346 3-3s-1.346-3-3-3z" fill="#6e83b7"/>
    <rect width="100px" height="100px"/>
    </svg>
`]
